// 引入vue
import Vue from 'vue'
// 引入Vuex
import Vuex from 'vuex'
// 应用vue插件
Vue.use(Vuex)
// // 导入模块化
import admin from './module/admin'

// // 创建store并导出
const store=new Vuex.Store({
    modules:{
        admin,
    }
})
// 导出去给所有的组件使用导出store
export default store