// import {getMenus} from "../../api/user"
const admin={
    namespaced:true,
    state:{
        isCollapse:false,//控制收缩
        breadcrumbs:[],//面包屑
        menuindex:[],//当前激活路由
        menuname:[],//当前所有激活菜单
        defaultindex:'index', // 当前菜单活动项/默认
    },
    getters:{

    },
    actions:{
        // 触发保存菜单栏的路径方法
        savemenuPath ({ commit }, payload){
            commit('savemenuPath', payload);
        },
        // 触发删除tabs标签页的方法
        deletemenuPath ({ commit }, payload) {
            commit('deletemenuPath', payload);
        },
        // 变更当前菜单活动项
        updatemenuactive ({ commit }, payload) {
            commit('updatemenuactive', payload)
        }
    },
    mutations:{
        // 修改收缩的状态
        editIsCollapse(state,_){
            state.isCollapse=!state.isCollapse
        },
        // 修改当前位置
        editBreadcrumbs(state,value){
            state.breadcrumbs=value
        },
        // 保存当前菜单栏的路径及名称
        savemenuPath (state, name_index_arr)  {
            state.defaultindex = name_index_arr[0]
            if (state.menuindex.includes(name_index_arr[0])) {
                 return(true)
            }else{   
                state.menuindex.push(name_index_arr[0]);
            }
            if (state.menuname.includes(name_index_arr[1])){
                return(true)
            }else{
                state.menuname.push(name_index_arr[1]);
            }
            // console.log(state.menuindex, state.menuname)
        },
        
        // 点击tabs标签页删除键时删除vuex数据
        deletemenuPath (state, index) {
            state.menuindex.splice(index, 1)
            state.menuname.splice(index, 1)
            // console.log(index)
        },
        //变更当前菜单活动项
        updatemenuactive (state, index){
            state.defaultindex = index
        },
    }
}
// 导出
export default admin