import Vue from 'vue';
//导入vue-router
import VueRouter from 'vue-router'

// 进度条
import NProgress from 'nprogress' 
import 'nprogress/nprogress.css'// nprogress样式文件

//应用插件
Vue.use(VueRouter)

//获取原型对象上的push函数
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
};

//创建router规则对象
const routes = [
	{name:"login",path:"/login",component:()=>import('../pages/user/UserLogin')},
    {name:"admin",path:"/admin",component:()=>import('../pages/Admin'),
        children:[
            {name:"account",path:"account",component:()=>import('../pages/promoteAccount/Account')},
            // {name:"home",path:"home",component:()=>import('../pages/home/Home')},
            {name:"stats",path:"stats",component:()=>import('../pages/promoteStatistics/Stats')},
            {name:"index",path:"index",component:()=>import('../pages/promoteStatistics/Realtime')},
            {name:"statshours",path:"statshours",component:()=>import('../pages/promoteStatistics/StatsHours')},
            {path:"/admin", redirect:"index",hidden:true},
            {path:"/admin/*", redirect:"404",hidden:true}
        ]
    },
    {path:"*",redirect:"/login"}
]
//创建router
const router = new VueRouter({
	routes
})
router.beforeEach((to,from,next)=>{
    NProgress.start();
    let token=localStorage.getItem("token");//获得token
    if(token){

        if(to.name=="login"){
            next({name:"index"})
        }else{
            next()
        }
    }else{//未登录 没有token页面，访问的时登录页面放行
        if(to.name=="login"){
            next()
        }else{//没有登录，访问后台页面的时候访问登录页面
            next("/login")
        }
    }
})
router.afterEach((to,from)=>{
    NProgress.done()
    document.title="语音财务"
})
//导出router
export default router