import Vue from 'vue'
import App from './App.vue'
Vue.config.productionTip = false
//引入axios
import axios from 'axios'
// 导入store
import store from './store'
Vue.config.productionTip = false
// 引入路由
import router from './router'


// 引入组件库
import {Button,Container,Form,FormItem,Input,Row,Message,MessageBox, Header, Aside, Main, Menu, MenuItem, Submenu, MenuItemGroup, Table, TableColumn, Tag, Switch, Dialog, Radio, RadioGroup, Select, RadioButton, DatePicker, Option, Pagination, Loading, Card, TimePicker, Breadcrumb, BreadcrumbItem, Col} from 'element-ui';
Vue.use(Button)
Vue.use(Row)
Vue.use(Input)
Vue.use(Form)
Vue.use(FormItem)
Vue.use(Container)
Vue.use(Header)
Vue.use(Aside)
Vue.use(Main)
Vue.use(Menu)
Vue.use(MenuItem)
Vue.use(Submenu)
Vue.use(MenuItemGroup)
Vue.use(Table)
Vue.use(TableColumn)
Vue.use(Tag)
Vue.use(Switch)
Vue.use(Dialog)
Vue.use(Radio)
Vue.use(RadioGroup)
Vue.use(Select)
Vue.use(RadioButton)
Vue.use(DatePicker)
Vue.use(Option)
Vue.use(Pagination)
Vue.use(Loading)
Vue.use(Card)
Vue.use(TimePicker)
Vue.use(Breadcrumb)
Vue.use(BreadcrumbItem)
Vue.use(Col)
Vue.prototype.$confirm = MessageBox.confirm;
Vue.prototype.$message=Message
new Vue({
  render: h => h(App),
  router,
  store,
}).$mount('#app')
