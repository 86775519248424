<template>
  <div>
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name:"App",
  mounted(){
	console.log(process.env.VUE_APP_BASE_API);
  }
}
</script>

<style>
*{
		margin: 0;
		padding: 0;
	}
	li{
		list-style-type: none;
	}
	.el-main::-webkit-scrollbar {
    width: 6px;
	}
	.el-main::-webkit-scrollbar-thumb {
		border-radius: 10px;
		background: rgba(0,0,0,0.2);
	}
	.el-main::-webkit-scrollbar-track {
		border-radius: 0;
		background: rgba(0,0,0,0.1);
	}
	.el-aside::-webkit-scrollbar {
		width: 4px;
	}
	.el-aside::-webkit-scrollbar-thumb {
		border-radius: 10px;
		background: rgba(0,0,0,0.2);
	}
	.el-aside::-webkit-scrollbar-track {
		border-radius: 0;
		background: rgba(0,0,0,0.1);
	}
	.el-cascader-menu{
		min-width: 200px;
		height: 200px;
	}
	/* 设置滚动的宽高 */
::-webkit-scrollbar{
  width: 4px;
  height: 6px;
}
/* ::-webkit-scrollbar-button — 滚动条上的按钮 (上下箭头) */
::-webkit-scrollbar-thumb{
  border-radius: 8px;
  background: #9FA8B8;
}
/* — 滚动条上的滚动滑块 */
::-webkit-scrollbar-thumb,
::-webkit-scrollbar-track {
  border-right-color: transparent;
  border-left-color: #9FA8B8;
}
</style>